var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row col-12 color_gris justify-center q-py-lg"},[_c('div',{staticClass:"row justify-between items-center col-12 col-md-10 q-pa-sm"},[_c('div',{class:'col-12 row justify-between  ' +
          (_vm.$q.screen.xs || _vm.$q.screen.sm ? 'q-py-none q-px-sm' : 'q-py-md ')},[_c('div',{staticClass:"row col-12 col-md-5 q-pa-md content-start  bg-section-servicios text-white",style:('min-height:250px;' +
            (_vm.$q.screen.xs || _vm.$q.screen.sm
              ? 'border-radius:20px; max-height: 350px;'
              : 'border-radius: 20px 0 0 20px; height: 24vw; '))},[_vm._m(0),_c('div',{staticClass:"col-12 row contenedorscroll scroll content-start",style:(_vm.$q.screen.xs || _vm.$q.screen.sm
              ? 'max-height: 250px; '
              : 'min-height: 16vw; height: 16vw')},_vm._l((_vm.servicios),function(servicio,index){return _c('q-btn',{key:index,staticClass:"q-ma-sm btnservicios text-bold cursor-pointer",attrs:{"flat":""},on:{"click":function($event){return _vm.toLink(servicio.link, '_blank')}}},[_vm._v(" "+_vm._s(servicio.nombre)+" ")])}),1)]),_c('div',{staticClass:"xs-hide sm-hide col-md-7",staticStyle:{"border-radius":"20px"}},[_c('div',[_c('q-carousel',{staticClass:"carouselServicios",staticStyle:{"border-radius":"0 20px 20px 0"},attrs:{"infinite":"","arrows":"","autoplay":_vm.autoplaycarousel,"transition-prev":"slide-right","transition-next":"slide-left","animated":"","height":"24vw"},on:{"mouseenter":function($event){_vm.autoplaycarousel = false},"mouseleave":function($event){_vm.autoplaycarousel = true}},model:{value:(_vm.slide2),callback:function ($$v) {_vm.slide2=$$v},expression:"slide2"}},[_c('q-carousel-slide',{staticStyle:{"overflow":"hidden !important"},attrs:{"name":"1"}},[_c('img',{staticClass:"cursor-pointer full-height",staticStyle:{"width":"100%"},attrs:{"alt":"Permiso Circulación","src":require("../../assets/Home/servicios06.webp")},on:{"click":function($event){return _vm.toLink('https://forms.gle/DtguCmeogKVbJCT99', '_blank')}}})]),_c('q-carousel-slide',{staticStyle:{"overflow":"hidden !important"},attrs:{"name":"2"}},[_c('img',{staticClass:"cursor-pointer full-height",staticStyle:{"width":"100%"},attrs:{"loading":"lazy","alt":"Veterinaria municipal","src":require("../../assets/Home/servicios-02.webp")},on:{"click":function($event){return _vm.toLink('http://imaarica.cl/veterinaria/', '_blank')}}})]),_c('q-carousel-slide',{staticStyle:{"overflow":"hidden !important"},attrs:{"name":"3"}},[_c('img',{staticClass:"cursor-pointer full-height",staticStyle:{"width":"100%"},attrs:{"loading":"lazy","alt":"Trámites municipales","src":require("../../assets/Home/servicios04.webp")},on:{"click":function($event){return _vm.toLink(
                    'https://muniarica.cl/tramites-municipales',
                    '_blank'
                  )}}})]),_c('q-carousel-slide',{staticStyle:{"overflow":"hidden !important"},attrs:{"name":"4"}},[_c('img',{staticClass:"cursor-pointer full-height",staticStyle:{"width":"100%"},attrs:{"alt":"Ofertas laborales","src":require("../../assets/Home/servicios05.webp")},on:{"click":function($event){return _vm.toLink('https://muniarica.cl/municipio/ofertas', '_blank')}}})])],1)],1)])]),_c('div',{staticClass:"row col-12 justify-between items-center q-mt-md"},[_c('div',{staticClass:"row col-12 justify-center"},[_c('div',{staticClass:"row justify-center items-center col-12 col-md-12"},[_c('div',{class:'col-1 row items-center ' +
                (_vm.$q.screen.xs ? 'justify-end' : 'justify-start')},[_c('q-btn',{staticClass:"color_secundario color_principal_inv_hover",attrs:{"aria-label":"Mostrar imagen anteriror","round":"","icon":"keyboard_arrow_left"},on:{"click":function($event){return _vm.scrollInfo('izquierda')}}})],1),_c('div',{staticClass:"col-9 col-md-10 no-wrap"},[_c('q-scroll-area',{ref:"scrollInfo",staticClass:"rounded-borders scrollArea",style:('width: 100%; ' +
                  (_vm.$q.screen.xs || _vm.$q.screen.sm
                    ? 'height: 160px!important'
                    : 'height: 172px!important')),attrs:{"horizontal":"","visible":false,"delay":1},on:{"scroll":_vm.scrollAreaFunctionInfo}},[_c('div',{staticClass:"row justify-center no-wrap"},[_c('div',{staticClass:"\n                      tamanio-contenedor-img\n                      row\n                      col-12 col-sm-2 col-md-2\n                      cursor-pointer\n                      q-mx-sm\n                      justify-center\n                    ",staticStyle:{"background":"#1111113b","border-radius":"10px"}},[_c('img',{staticClass:"image",staticStyle:{"width":"100% !important","height":"100% !important","border-radius":"10px"},attrs:{"loading":"lazy","alt":"Lipigas","src":require("../../assets/Home/image_flickr.webp")},on:{"click":function($event){return _vm.toLink(
                        'https://www.flickr.com/photos/muniarica/',
                        '_blank'
                      )}}})]),_c('div',{staticClass:"\n                      tamanio-contenedor-img\n                      row\n                      col-12 col-sm-2 col-md-2\n                      cursor-pointer\n                      q-mx-sm\n                      justify-center\n                    ",staticStyle:{"background":"#1111113b","border-radius":"10px"}},[_c('img',{staticClass:"image",staticStyle:{"width":"100% !important","height":"100% !important","border-radius":"10px"},attrs:{"loading":"lazy","alt":"Lipigas","src":require("../../assets/Home/image_aricasegura.webp")},on:{"click":function($event){return _vm.toLink(
                        'https://www.muniarica.cl/arica-segura',
                        '_blank'
                      )}}})]),_c('div',{staticClass:"\n                     tamanio-contenedor-img\n                      row\n                      col-12 col-sm-2 col-md-2\n                      cursor-pointer\n                      q-mx-sm\n                      justify-center\n                    ",staticStyle:{"background":"#1111113b","border-radius":"10px"}},[_c('img',{staticClass:"image",staticStyle:{"width":"100% !important","height":"100% !important","border-radius":"10px"},attrs:{"loading":"lazy","alt":"La Fuerza del Sol Carnaval","src":require("../../assets/Home/image16.webp")},on:{"click":function($event){return _vm.toLink(
                        'https://www.aricafuerzadelsol.cl/',
                        '_blank'
                      )}}})]),_c('div',{staticClass:"  \n                     tamanio-contenedor-img\n                      row\n                      col-12 col-sm-2 col-md-2\n                      cursor-pointer\n                      q-mx-sm\n                      justify-center\n                    ",staticStyle:{"background":"#1111113b","border-radius":"10px"}},[_c('img',{staticClass:"image",staticStyle:{"width":"100% !important","height":"100% !important","border-radius":"10px"},attrs:{"loading":"lazy","alt":"Parque Centenario","src":require("../../assets/Home/Rectangle74.webp")},on:{"click":function($event){return _vm.toLink(
                        'https://imaarica.cl/ParqueCentenario/',
                        '_blank'
                      )}}})])])])],1),_c('div',{class:'col-1 row items-center ' +
                (_vm.$q.screen.xs ? 'justify-start' : 'justify-end')},[_c('q-btn',{staticClass:"color_secundario color_principal_inv_hover",attrs:{"aria-label":"Mostrar siguiente imagen","round":"","icon":"keyboard_arrow_right"},on:{"click":function($event){return _vm.scrollInfo('derecha')}}})],1)])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('p',{staticClass:"col-12 sub_title text-bold text-primary"},[_vm._v(" Trámites y Servicios ")]),_c('p',{staticClass:"col-12 q-mt-sm q-mb-sm"},[_vm._v(" Selecciona el servicio que necesitas ")])])}]

export { render, staticRenderFns }