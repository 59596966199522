
































































































































































































































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {}
})
export default class EnlacesInteresComponent extends Vue {
  private servicios: Array<any> = [];
  private horizontalPercentageInfo = 0;
  private autoplaycarousel = true;
  private slide2 = "1";

  private created() {
    this.getServicios();
    this.autoScrollInfo();
    this.encontrarBtn();
  }

  private mounted() {
    this.encontrarBtn();
  }

  private encontrarBtn() {
    const elementos = document.querySelectorAll(".carouselServicios");
    elementos.forEach(elemento => {
      const buttons = elemento.querySelectorAll("button");
      buttons.forEach(buttonElement => {
        buttonElement.setAttribute(
          "aria-label",
          "Desplazar imágenes del carrusel"
        );
      });
    });
  }

  private getServicios() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      // .get("/anuncios")
      .get("/servicios/general?tipo=servicios")
      .then((res: any) => {
        // console.log(res);
        let servicio = res.data;
        for (let i = 0; i < servicio.length; i++) {
          for (let j = 0; j < servicio[i].servicios.length; j++) {
            // console.log(this.servicios[i].servicios[j]);
            this.servicios.push(servicio[i].servicios[j]);
          }
        }
        // console.log(this.servicios);
        this.$q.loading.hide();
      })
      .catch((err: any) => {
        this.$q.loading.hide();
        console.log(err);
      });
  }

  private toLink(item: any, target: any) {
    if (item && item != "") {
      window.open(item, target);
    } else {
      return;
    }
  }
  private scrollInfo(direccion: string) {
    // console.log(this.$refs.scrollInteres);
    if (this.$refs.scrollInfo as any) {
      const size = (this.$refs.scrollInfo as any).scrollSize;
      const position = (this.$refs.scrollInfo as any).scrollPosition;
      // console.log("size", size);
      //       console.log("position", position);

      if (direccion == "derecha") {
        // console.log("horizontalporcentage", this.horizontalPercentageInteres);
        if (this.horizontalPercentageInfo >= 0.9) {
          (this.$refs.scrollInfo as any).setScrollPosition(0, 180);
        } else {
          (this.$refs.scrollInfo as any).setScrollPosition(position + 260, 150);
        }
      } else {
        if (position - 90 < 0) {
          if (position > 0) {
            (this.$refs.scrollInfo as any).setScrollPosition(0, 180);
          } else {
            (this.$refs.scrollInfo as any).setScrollPosition(size, 120);
          }
        } else {
          (this.$refs.scrollInfo as any).setScrollPosition(position - 230, 150);
        }
      }
    }
  }
  private autoScrollInfo() {
    this.scrollInfo("derecha");
    setTimeout(this.autoScrollInfo, 5000);
  }

  private scrollAreaFunctionInfo(info: any) {
    this.horizontalPercentageInfo = info.horizontalPercentage;
  }
}
